<template>
  <div>
    <vs-card>
      <div>
        <h4>Unauthorized Contacts</h4>
      </div>
      <br/>
      <vs-table stripe  :data="unauth_contacts">
        <template slot="thead">
          <vs-th>
            Can-ID
          </vs-th>
          <vs-th>
            Lead Name
          </vs-th>
          <vs-th>
            Accessed Spoc
          </vs-th>
          <vs-th>
            Accessed Spoc's GM
          </vs-th>
          <vs-th>
            Lead owner
          </vs-th>
          <vs-th>
            Lead owner's GM
          </vs-th>
          <vs-th>
            Type
          </vs-th>
          <vs-th>
            Accessed Time
          </vs-th>
          <vs-th>
          Info
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].unauthorized_contacts.identity">
              {{data[indextr].unauthorized_contacts.identity}}
            </vs-td>
            <vs-td :data="data[indextr].unauthorized_contacts.candidate_name">
              {{data[indextr].unauthorized_contacts.candidate_name}}
            </vs-td>
            <vs-td :data="data[indextr].unauthorized_contacts.accessed_spoc">
              {{data[indextr].unauthorized_contacts.accessed_spoc}}
            </vs-td>
            <vs-td :data="data[indextr].unauthorized_contacts.accessed_spoc_gm">
              {{data[indextr].unauthorized_contacts.accessed_spoc_gm}}
            </vs-td>
            <vs-td :data="data[indextr].unauthorized_contacts.lead_owner_name">
              {{data[indextr].unauthorized_contacts.lead_owner_name}}
            </vs-td>
            <vs-td :data="data[indextr].unauthorized_contacts.lead_owner_gm">
              {{data[indextr].unauthorized_contacts.lead_owner_gm}}
            </vs-td>
            <vs-td :data="data[indextr].type">
              {{data[indextr].type}}
            </vs-td>
            <vs-td :data="data[indextr].accessed_at">
              {{customFormatter(data[indextr].accessed_at)}}
            </vs-td>
            <vs-td>
              <div @click="openPopupCallEmit(tr)">
                <vs-icon
                  icon="info_outline"
                  size="small"
                  color="dark"
                ></vs-icon>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-row style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-pagination
          :total="total_unauthorized_page"
          v-model="current_unauthoriezed_Page"
        ></vs-pagination>
      </vs-col>
    </vs-row>
      <br/>
      <h4>Individual Whatsapp Messages</h4>
      <br/>
      <vs-table  stripe :data="individual_wa_msgs">
        <template slot="thead">
          <vs-th> Can-ID </vs-th>
          <vs-th> Lead Name</vs-th>
          <vs-th> Message sent Spoc </vs-th>
          <vs-th> Spoc's GM </vs-th>
          <vs-th> Lead owner </vs-th>
          <vs-th> Lead owner's GM </vs-th>
          <vs-th> WA Message </vs-th>
          <vs-th> Created At </vs-th>
          <vs-th> Info</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td>{{data[indextr].identity}}</vs-td>
            <vs-td>{{data[indextr].lead_name}}</vs-td>
            <vs-td>{{data[indextr].msg_sent_spoc}}</vs-td>
            <vs-td>{{data[indextr].msg_sent_spoc_gm}}</vs-td>
            <vs-td>{{data[indextr].lead_owner_name}}</vs-td>
            <vs-td>{{data[indextr].lead_owner_gm}}</vs-td>
            <vs-td>{{data[indextr].wa_message}}</vs-td>
            <vs-td>{{customFormatter(data[indextr].msg_sent_at)}}</vs-td>
            <vs-td>
            <div @click="openPopupEmit(tr)">
              <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
            </div>
          </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-row style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-pagination
          :total="total_message_page"
          v-model="current_message_Page"
        ></vs-pagination>
      </vs-col>
    </vs-row>
    </vs-card>
  </div>
</template>

<script>
import moment from "moment";
import ApiServices from "../ApiServices.js";
export default {
   data:()=>({
    unauth_contacts:[],
    individual_wa_msgs: [],
    total_unauthorized_page:0,
    current_unauthoriezed_Page:0,
    total_message_page:0,
    current_message_Page:0,
  }),
  watch:{
    current_unauthoriezed_Page(val){
      this.getUnauthorizedContacts(val);
    },
    current_message_Page(val){
    this.getWhatsAppSentData(val);
    }
  },
  mounted(){
    this.getUnauthorizedContacts(1);
    this.getWhatsAppSentData(1);
  },
  methods:{
    customFormatter(accessed_date) {
      return moment(accessed_date).format("DD-MMM-YYYY hh:mm:ss");
    },
    openPopupEmit(payload) {
      this.openBigPopup(payload.mwb_id);
    },
    openPopupCallEmit(payload){
      this.openBigPopup(payload.unauthorized_contacts.mwb_id);
    },
    getUnauthorizedContacts(page){
      this.$vs.loading();
      ApiServices.getUnauthorizedContacts(page)
        .then((response) => {
          console.log("getUnauthorizedContacts",response);
          this.unauth_contacts = response.data.data;
          this.total_unauthorized_page = response.data.last_page;
          this.current_unauthoriezed_Page = response.data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    getWhatsAppSentData(page){
      this.$vs.loading();
      ApiServices.getWhatsAppSentData(page)
        .then((response) => {
          console.log("getUnauthorizedContacts",response);
          this.individual_wa_msgs = response.data.data;
          this.total_message_page = response.data.last_page;
          this.current_message_Page = response.data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    }
  }
}
</script>

<style>

</style>